<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo: { title: 'Home' },

    extends: View,

    mixins: [
      LoadSections([
        // 'theme-features',
        'hero-app',
        // 'features',
        // 'features-invert',
        // 'social-media',
        'pro-features2',
        'pro-features3',
        'demo-request',
        // 'pro-features4',
        'address-app',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
